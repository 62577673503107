<template>
    <div class="row">
      <Timer v-for="parc in parcours" :key="parc.key" :parcourskey="parc.key" />
    </div>
</template>

<script>
import Timer from './Timer'
import { db } from '../firebaseDb'
import store from "../store"

export default {
  name: 'App',
  title: 'Einweisungen',
  components: {
    Timer
  },
  data () {
    return {
      backendVersion: null,
      parcours: []
    }
  },
  created () {
    const parcoursRef = db.collection('parcours')
      parcoursRef.where('uid', '==', store.getters.user.data.uid).orderBy('pos').onSnapshot((snapshotChange) => {
      this.parcours = []
      snapshotChange.forEach((doc) => {
        this.parcours.push({
          key: doc.id,
          parcoursID: doc.data().parcoursID
        })
      })
    })
  },
  mounted () {
    if (!(localStorage.lastUpdateCheck) || Date.now() - parseInt(localStorage.lastUpdateCheck) > 18000000) { // 5hrs
      localStorage.lastUpdateCheck = Date.now()
      const version = db.collection('version').doc('currentVersion')
      version.get().then((currentVersion) => {
        if (currentVersion.exists) {
          this.backendVersion = currentVersion.data().v
          if (currentVersion.data().v !== null && currentVersion.data().v !== store.getters.appVersion) {
            console.log('Update detected, reload app')
            window.location.reload()
          }
        }
      })
    }
  }
}
</script>
